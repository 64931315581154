export function getFromStorage(key) {
  if (!key) {
    return null
  }
  try {
    const valueStr = localStorage.getItem(key)
    if (valueStr) {
      return JSON.parse(valueStr)
    }
    return null
  } catch (err) {
    return null
  }
}

export function setInStorage(key, obj) {
  if (!key) {
    console.error('Error: Key is missing')
  }
  try {
    localStorage.setItem(key, JSON.stringify(obj))
  } catch (err) {
    console.error(err)
  }
}

export function destroyStorage(key) {
  if (!key) {
    return null
  }
  try {
    if (
      localStorage.removeItem(key) &&
      localStorage.removeItem('tipo_' + process.env.REACT_APP_PROYECTO) &&
      localStorage.removeItem('nombre_' + process.env.REACT_APP_PROYECTO) &&
      localStorage.removeItem('intendencia_' + process.env.REACT_APP_PROYECTO)
    ) {
      return true
    }
    return null
  } catch (err) {
    return null
  }
}

import { destroyStorage, getFromStorage } from './Storage'
class Auth {
  constructor() {
    this.autenticado = false
  }

  login(cb) {
    this.autenticado = true
  }

  logout(cb) {
    destroyStorage('tipo_' + process.env.REACT_APP_PROYECTO)
    destroyStorage('nombre_' + process.env.REACT_APP_PROYECTO)
    destroyStorage('intendencia_' + process.env.REACT_APP_PROYECTO)
    const obj = destroyStorage('the_main_app_' + process.env.REACT_APP_PROYECTO)
    if (obj == null) {
      this.autenticado = false
    } else {
      this.autenticado = false
    }
    cb()
  }
  isAutenticado() {
    const obj = getFromStorage('the_main_app_' + process.env.REACT_APP_PROYECTO)
    if (obj && obj.token) {
      this.autenticado = true
    } else {
      this.autenticado = false
    }
    return this.autenticado
  }
}
export default new Auth()

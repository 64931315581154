import React from "react";

const Login = React.lazy(() => import("./pages/ModulosBase/Login/Login"));
const Mapa = React.lazy(() => import("./pages/ModulosBase/Mapa/Mapa"));
const Dashboard = React.lazy(() =>
  import("./pages/ModulosBase/Dashboard/Dashboard")
);

//Reportes Hidrogeología
const ReporteC = React.lazy(() =>
  import("./pages/ModulosBase/Report/ReporteC/ReporteC")
);

const ReporteDiarioPAgua = React.lazy(() =>
  import("./pages/ModulosBase/Report/ReporteDiarioPAgua/ReporteB2")
);

const ReporteBMensual = React.lazy(() =>
  import("./pages/ModulosBase/Report/ReporteMensualPAgua/ReporteBMensual")
);

const ReporteB = React.lazy(() =>
  import("./pages/ModulosBase/Report/ReporteSemanalPAgua/ReporteB")
);

const ReporteBSP = React.lazy(() =>
  import("./pages/ModulosBase/Report/ReporteSemanalPAgua/ReporteBSP")
);

const ReporteA = React.lazy(() =>
  import("./pages/ModulosBase/Report/ReporteA/ReporteA")
);

const ReporteA2 = React.lazy(() =>
  import("./pages/ModulosBase/Report/ReporteA/ReporteA2")
);

const ReporteA3 = React.lazy(() =>
  import("./pages/ModulosBase/Report/ReporteA/ReporteA3")
);

const ReporteD = React.lazy(() =>
  import("./pages/ModulosBase/Report/ReporteDiario/ReporteD")
);

const ReporteMulti = React.lazy(() =>
  import("./pages/ModulosBase/Report/ReporteMultiP/ReporteMultiP")
);

const ReporteDique = React.lazy(() =>
  import("./pages/ModulosBase/Report/0NuevosHidro/ReporteDique/ReporteDique")
);

//Reportes Drenaje

const ReporteSemanalDrenaje = React.lazy(() =>
  import(
    "./pages/ModulosBase/Report/SIDrenaje/ReporteSemanal/ReporteSemanalDrenaje"
  )
);

const ReporteMensualDrenaje = React.lazy(() =>
  import("./pages/ModulosBase/Report/SIDrenaje/ReporteMensual/ReporteMDrenaje")
);

const ReporteDiarioDrenaje = React.lazy(() =>
  import("./pages/ModulosBase/Report/SIDrenaje/ReporteDiario/ReporteDDiario")
);

//Menues de reportes

const MenuIntendencia = React.lazy(() =>
  import("./pages/ModulosBase/Report/1MenuIntendencias/MenuIntendencias")
);

const MenuHidro = React.lazy(() =>
  import("./pages/ModulosBase/Report/1MenuIntendencias/MenuHidro")
);

const MenuGeo = React.lazy(() =>
  import("./pages/ModulosBase/Report/1MenuIntendencias/MenuGeo")
);

const MenuDrenaje = React.lazy(() =>
  import("./pages/ModulosBase/Report/1MenuIntendencias/MenuDrenaje")
);

//Administradores
const AdminSitios = React.lazy(() =>
  import("./pages/ModulosBase/Admin/adminalertas/ASitios100E")
);

const AdminEquipos = React.lazy(() =>
  import("./pages/ModulosBase/Admin/adminequipos/Equipos")
);

const AdminConstantes = React.lazy(() =>
  import("./pages/ModulosBase/Admin/adminconstantes/Constantes")
);

const AdminUsuario = React.lazy(() =>
  import("./pages/ModulosBase/Admin/adminusers/PanelUsuarios")
);

const AdminUsuarioAdd = React.lazy(() =>
  import("./pages/ModulosBase/Admin/adminusers/AgregarUser")
);

const AdminSMS = React.lazy(() =>
  import("./pages/ModulosBase/Admin/adminsms/SmsConfigClass")
);

const AdminCSV = React.lazy(() =>
  import("./pages/ModulosBase/Admin/admincsv/UploadCSV")
);

const AdminKML = React.lazy(() =>
  import("./pages/ModulosBase/Admin/adminkml/UploadKML")
);

//Mi Cuenta
const MiCuenta = React.lazy(() =>
  import("./pages/ModulosBase/Admin/adminusers/micuenta/MiCuenta")
);

//Modulos Especiales
const Interpretacion = React.lazy(() =>
  import("./pages/ModulosEspeciales/ModInterpretacion/Interpretacion")
);

const Hidroquimica = React.lazy(() =>
  import("./pages/ModulosEspeciales/Hidroquimica/Hidroquimica")
);

const GrupoSitios = React.lazy(() =>
  import("./pages/ModulosEspeciales/GrupoSitios/GrupoSitios")
);
/*
*SCADAS rutas
*/
const menuScada = React.lazy(() =>
  import("./pages/ModulosEspeciales/SCADAS/index")
);
const hidroSCA = React.lazy(() =>
  import("./pages/ModulosEspeciales/SCADAS/HidroSCA")
);
const routes = [
  //rutas sin layout
  { path: "/", component: Login, isWithoutLayout: true, exact: true },
  { path: "/mapa", component: Mapa, isWithoutLayout: true, exact: true },
  {
    path: "/dashboard/:vista/:tipo/:id",
    component: Dashboard,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reportec",
    component: ReporteC,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reporteb12",
    component: ReporteDiarioPAgua,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reportebmensual",
    component: ReporteBMensual,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reporteb1",
    component: ReporteB,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reporteb1sp",
    component: ReporteBSP,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reportea1",
    component: ReporteA,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reportea2",
    component: ReporteA2,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reportea3",
    component: ReporteA3,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reported",
    component: ReporteD,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reportemp",
    component: ReporteMulti,
    isWithoutLayout: false,
    exact: true,
  },

  {
    path: "/reportes/menuintendencias",
    component: MenuIntendencia,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/menuhidro",
    component: MenuHidro,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/menugeo",
    component: MenuGeo,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/menudrenaje",
    component: MenuDrenaje,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reportesemanaldrenaje",
    component: ReporteSemanalDrenaje,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reportemensualdrenaje",
    component: ReporteMensualDrenaje,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reportediariodrenaje",
    component: ReporteDiarioDrenaje,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/reportes/reportedique",
    component: ReporteDique,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/me",
    component: MiCuenta,
    isWithoutLayout: true,
    exact: true,
  },
  {
    path: "/admin/sitios",
    component: AdminSitios,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/admin/equipos",
    component: AdminEquipos,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/admin/constantes",
    component: AdminConstantes,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/admin/usuario",
    component: AdminUsuario,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/admin/usuarioadd",
    component: AdminUsuarioAdd,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/admin/sms",
    component: AdminSMS,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/admin/csv",
    component: AdminCSV,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/admin/kml",
    component: AdminKML,
    isWithoutLayout: false,
    exact: true,
  },
  {
    path: "/mod/interpretacion",
    component: Interpretacion,
    isWithoutLayout: true,
    exact: true,
  },
  {
    path: "/mod/hidroquimica",
    component: Hidroquimica,
    isWithoutLayout: true,
    exact: true
  },
  {
    path: "/mod/grupos",
    component: GrupoSitios,
    isWithoutLayout: true,
    exact: true
  },
  {
    path: "/scada/menu",
    component: menuScada,
    isWithoutLayout: false,
    exact: true
  },
  {
    path: "/scada/panel/:id",
    component: hidroSCA,
    isWithoutLayout: true,
    exact: true
  }
];

export default routes;
